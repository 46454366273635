import React, {useEffect} from 'react';
import {useTheme} from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import {Accordion, AccordionDetails, AccordionSummary, Menu} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemText from "@mui/material/ListItemText";
import Cookies from "js-cookie";
import axios from "axios";

import CristalLogoLight from './../images/logo-cristalhub-light.svg';
import CristalLogoDark from './../images/logo-cristalhub-dark.svg';
import IconBackDark from './../images/icon-arrow-left-back-dark.svg';
import IconBackLight from './../images/icon-arrow-left-back-light.svg';
import * as constantes from './Parts/AlveoleConstants';
import {
    CristalBackBleuButton,
    CristalBlueButton,
    CristalLogo,
    CristalPinkButton, CristalWhiteButton,
    StyledBadge, useStyles
} from "./Parts/ComponentLayout";

import {alveoleLogoSvg} from './Parts/AlveoleConstants'

const Layout = ({children}) => {

    const classes = useStyles();
    const [bLoading, setBLoading] = React.useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [calendars, setCalendars] = React.useState([]);
    const [documents, setDocuments] = React.useState([]);
    const [bShowNotifications, setBShowNotifications] = React.useState(false); //fenêtre d'affichage notifications
    const [bConsents, setBConsents] = React.useState(false);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    useEffect(() => {
        const timer = setTimeout(() => {
            setBLoading(false);
        }, 4000); //  milliseconds
        return () => clearTimeout(timer); // Annule le timer si le composant est démonté avant le délai
    }, []);

    const fOpenNotifications = () => {
        setBShowNotifications(true);
    }
    const fCloseNotifications = () => {
        setBShowNotifications(false);
    }
    const fRemoveNotifications = (value) => {
        // calendars = calendars.filter((calendar) => calendar['@id'] === response.data['notification']['@id'])
        axios.post(`${constantes.zUrlBackNotifications}/remove-notification`, {
                idUser: Cookies.get('idUser'),
                idNotification: value
            },
            {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            })
            .then((response) => {
                console.log(response.data['notification']['externalId']) // élément supprimé
                if (response.data['notification']['type'] === 'calendar') {
                    setCalendars(calendars.filter((calendar) => calendar['externalId'] !== value));
                } else {
                    setDocuments(documents.filter((doc) => doc['externalId'] !== value));
                }
            })
            .catch((error) => {
                console.log(error)
            })
        ;
    }

    // Recuperation des notifications
    const fetchNotifications = () => {
        setBLoading(true);
        console.log('récup data notif')
        axios.post(`${constantes.zUrlBackNotifications}/notifications`,
            {
                token: Cookies.get('token'),
                societe: Cookies.get('socId'),
                compte: Cookies.get('accountId'),
                idUser: Cookies.get('idUser')
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                console.log(response.data);
                if (response.status === 200) {
                    // Récupération Datas calendar
                    console.log(response.data['calendarList']);
                    setCalendars(response.data['calendarList']);
                    // Récupération Datas documents
                    setDocuments(response.data['zeenDocsList']);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setBLoading(false);
            });
    };

    useEffect(() => {
        let accountAcces;
        axios.post(`${constantes.zUrlBackNotifications}/customer-check`,
            {
                token: Cookies.get('token'),
                accountName: Cookies.get('compteParamNom')
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
            .then(response => {
                if (response.status === 200) {
                    accountAcces = response.data['accountAcces'];
                    console.log(accountAcces);
                }

            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                if (accountAcces !== null) {
                    fetchNotifications();
                }
            });

    }, []);

    useEffect(() => {
        axios.post(`${constantes.zUrlBackNotifications}/notifications-consents`,
            {token: Cookies.get('token')}, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                let notificationValue = response.data['consentNotif'];
                setBConsents(notificationValue);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const handleCloseLoading = () => {
        setBLoading(false);
    };

    const handleHome = () => {
        window.location.assign(constantes.zURLhome);
    }
    const handleRetour = () => {
        window.history.back();
    }
    const handleProfile = () => {
        window.location.assign(constantes.zURLprofile)
    }
    return (
        <>
            <CssBaseline/>
            <Container maxWidth="xl">
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={bLoading}
                    onClick={handleCloseLoading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Box sx={{flexGrow: 1}}>
                    <Grid container>
                        <Grid item md={12} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{margin: '36px 0px 14px'}}
                            >
                                <Grid item md={6} xs={6}>
                                    <CristalLogo
                                        src={theme.palette.mode === 'light' ? `${CristalLogoLight}` : `${CristalLogoDark}`}
                                        loading="lazy"
                                        sx={{maxWidth: '100%'}}/>
                                </Grid>
                                {Cookies.get('socId') && Cookies.get('accountId') && (
                                    <Grid item md={6} xs={6}>
                                        <Grid container
                                              direction="row"
                                              justifyContent="flex-end"
                                              alignItems="flex-start">
                                            {calendars.length === 0 && documents.length === 0 ? (
                                                <Grid item>
                                                    <CristalBlueButton
                                                        disableRipple
                                                        sx={{
                                                            cursor: 'default',
                                                            marginRight: '.5rem!important'
                                                        }}
                                                    >
                                                        <img src={require('./../images/icon-notif.svg').default}
                                                             alt="CristalHub"
                                                             className="CristalNotifButton"/>
                                                    </CristalBlueButton>
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Box>
                                                        <StyledBadge theme
                                                                     badgeContent=" "
                                                                     overlap="circular">
                                                            <CristalBlueButton
                                                                disableRipple
                                                                onClick={fOpenNotifications}
                                                                sx={{marginRight: '.5rem!important'}}
                                                            >
                                                                <img
                                                                    src={require('./../images/icon-notif.svg').default}
                                                                    alt="CristalHub"
                                                                    className="CristalNotifButton"/>
                                                            </CristalBlueButton>
                                                        </StyledBadge>
                                                    </Box>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        open={bShowNotifications}
                                                        onClose={fCloseNotifications}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        style={{marginTop: '80px'}}
                                                        PaperProps={{
                                                            sx: {
                                                                padding: '0 14px 6px 14px',
                                                                width: '35vw',
                                                                borderRadius: '10px',
                                                                backgroundImage: 'none',
                                                                backgroundColor: theme.palette.mode === 'light' ? '#B3EDFA' : '#004072',
                                                            },
                                                        }}>
                                                        <Grid container
                                                              direction="row"
                                                              justifyContent="space-between"
                                                              alignItems="center" sx={{marginBottom: '8px'}}>
                                                            <Typography
                                                                sx={{fontFamily: 'PoppinsSemiBold'}}>
                                                                Notifications
                                                            </Typography>
                                                            <CristalPinkButton sx={{
                                                                backgroundSize: '32px 37px',
                                                                height: '37px',
                                                                width: '32px',
                                                                marginRight: '8px'
                                                            }}
                                                                               disableRipple>
                                                                <img
                                                                    src={require('./../images/icon-close-x.svg').default}
                                                                    alt="CristalHub"
                                                                    onClick={fCloseNotifications}
                                                                    className="CristalNotifButton"/>
                                                            </CristalPinkButton>
                                                        </Grid>

                                                        <Grid container
                                                              direction="column">
                                                            {calendars.length > 0 && (
                                                                <Accordion
                                                                    sx={{
                                                                        backgroundImage: 'none',
                                                                        margin: '14px 0!important',
                                                                        borderRadius: '10px!important',
                                                                        backgroundColor: theme.palette.mode === 'light' ? '#CCF3FC' : '#00508F'
                                                                    }}>
                                                                    <AccordionSummary
                                                                        aria-controls="panel1-content"
                                                                        id="panel1-header"
                                                                        expandIcon={<ExpandMoreIcon/>}
                                                                        sx={{
                                                                            borderRadius: '10px',
                                                                            backgroundImage: 'none',
                                                                            backgroundColor: theme.palette.mode === 'light' ? '#CCF3FC' : '#00508F',

                                                                        }}>
                                                                        <Typography>
                                                                            Rendez-vous ({calendars.length})
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    {Array.isArray(calendars) && calendars.map((calendar, index) => (
                                                                        <AccordionDetails
                                                                            sx={{
                                                                                borderTop: theme.palette.mode === 'light' ? "1px solid #00233E" : "1px solid #FFF"
                                                                            }}
                                                                            key={index}>
                                                                            <Grid container direction="column"
                                                                                  align-item="center"
                                                                                  justify-content="space-between">

                                                                                <ListItemText
                                                                                    primary={`${calendar.dateEvenement} - ${calendar.complement}`}/>

                                                                                <Grid container direction="row">
                                                                                    <ListItemText
                                                                                        secondary={`${calendar.label}`}/>
                                                                                    <Grid item sx={{cursor: "pointer"}}
                                                                                          onClick={() => fRemoveNotifications(calendar.externalId)}>
                                                                                        <img
                                                                                            src={theme.palette.mode === 'light' ? require('../images/icon-trash-01.svg').default : require('../images/icon-trash-01-dark.svg').default}
                                                                                            alt="suppression rendez-vous notification icone"
                                                                                            className="CristalNotifButton"/>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </AccordionDetails>
                                                                    ))}
                                                                </Accordion>
                                                            )}
                                                            {documents.length > 0 && (
                                                                <Accordion
                                                                    sx={{
                                                                        margin: '14px 0!important',
                                                                        backgroundImage: 'none',
                                                                        borderRadius: '10px!important',
                                                                        backgroundColor: theme.palette.mode === 'light' ? '#CCF3FC' : '#00508F'
                                                                    }}>
                                                                    <AccordionSummary
                                                                        aria-controls="panel1-content"
                                                                        id="panel1-header"
                                                                        expandIcon={<ExpandMoreIcon/>}
                                                                        sx={{
                                                                            borderRadius: '10px',
                                                                            backgroundImage: 'none',
                                                                            backgroundColor: theme.palette.mode === 'light' ? '#CCF3FC' : '#00508F',
                                                                        }}>
                                                                        <Typography>
                                                                            Documents non lus ({documents.length})
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    {/*création d'un nouveau tableau groupé par valeur d'id correspondant aux alvéoles*/}
                                                                    <Grid item sx={{
                                                                        borderTop: theme.palette.mode === 'light' ? "1px solid #00233E" : "1px solid #FFF"
                                                                    }}>
                                                                        {Array.isArray(documents) && Object.entries(documents.reduce((groups, document) => {
                                                                            const groupName = document.alveole; // création des groupes en fonction de la value custom_n1 (id discriminant alvéole)
                                                                            if (!groups[groupName] && groups[groupName] !== 'Mes rendez-vous') {// le groupe n'existe pas
                                                                                groups[groupName] = [];
                                                                            }
                                                                            groups[groupName].push(document); // ajout au groupe existant
                                                                            return groups;
                                                                        }, {})).map(([groupName, group], index) => (
                                                                            <AccordionDetails key={index}>
                                                                                <Accordion
                                                                                    sx={{
                                                                                        borderRadius: '10px!important',
                                                                                        backgroundImage: 'none',
                                                                                        backgroundColor: theme.palette.mode === 'light' ? '#e4f7fc' : '#3372a4'
                                                                                    }}>
                                                                                    <AccordionSummary
                                                                                        aria-controls="panel1-content"
                                                                                        id="panel1-header"
                                                                                        expandIcon={<ExpandMoreIcon/>}
                                                                                        sx={{
                                                                                            borderRadius: '10px!important',
                                                                                            backgroundImage: 'none',
                                                                                            backgroundColor: theme.palette.mode === 'light' ? '#e4f7fc' : '#3372a4',
                                                                                        }}>
                                                                                        <Typography>{groupName} ({group.length})</Typography>
                                                                                    </AccordionSummary>
                                                                                    {/*Sous-catégorie d'accordion*/}
                                                                                    {group.map((document, index) => (
                                                                                        <AccordionDetails
                                                                                            key={index}>
                                                                                            <Grid container
                                                                                                  direction="column"
                                                                                                  align-item="center"
                                                                                                  justify-content="space-between">

                                                                                                <ListItemText
                                                                                                    onClick={fCloseNotifications}
                                                                                                    primary={`${document.label} ${document.complement ?? ""}`}/>

                                                                                                <Grid item>
                                                                                                    <Grid container
                                                                                                          direction="row">
                                                                                                        <ListItemText
                                                                                                            secondary={document.dateEvenement.split(' ')[0]}/>{/*Affiche uniquement la date*/}
                                                                                                        <Grid item
                                                                                                              sx={{cursor: "pointer"}}
                                                                                                              onClick={() => fRemoveNotifications(document.externalId)}>
                                                                                                            <img
                                                                                                                src={theme.palette.mode === 'light' ? require('../images/icon-trash-01.svg').default : require('../images/icon-trash-01-dark.svg').default}
                                                                                                                alt="suppression rendez-vous notification icone"
                                                                                                                className="CristalNotifButton"/>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </AccordionDetails>
                                                                                    ))}
                                                                                </Accordion>
                                                                            </AccordionDetails>
                                                                        ))}
                                                                    </Grid>

                                                                </Accordion>
                                                            )}
                                                        </Grid>
                                                    </Menu>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                {bConsents === true ? (
                                                    <Box>
                                                        <StyledBadge
                                                            classes={{badge: classes.customBadge}}
                                                            color="default" badgeContent=" " overlap="circular">
                                                            <CristalBlueButton onClick={handleProfile}
                                                                               disableRipple>
                                                                <img
                                                                    src={require('./../images/icon-settings.svg').default}
                                                                    alt="CristalHub"
                                                                    className="CristalSettingsButton"/>
                                                            </CristalBlueButton>
                                                        </StyledBadge>
                                                    </Box>
                                                ) : (
                                                    <CristalBlueButton onClick={handleProfile} disableRipple>
                                                        <img
                                                            src={require('./../images/icon-settings.svg').default}
                                                            alt="CristalHub"
                                                            className="CristalSettingsButton"/>
                                                    </CristalBlueButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="IconsNavigationContainer">
                        <Grid item md={12} xs={12}>
                            <Grid
                                container
                                direction="row">
                                <Grid item>
                                    <CristalPinkButton
                                        onClick={handleHome}
                                        disableRipple>
                                        <img src={require('./../images/icon-home-blc.svg').default}
                                             alt="CristalHub"
                                             className="CristalIconButton"/>
                                    </CristalPinkButton>
                                </Grid>
                                <Grid item>
                                    <CristalBackBleuButton
                                        onClick={handleRetour}
                                        disableRipple>
                                        <img
                                            src={theme.palette.mode === 'light' ? `${IconBackLight}` : `${IconBackDark}`}
                                            alt="CristalHub"
                                            className="CristalIconButton"/>
                                    </CristalBackBleuButton>
                                </Grid>
                                <Grid item>
                                    <CristalWhiteButton
                                        disableRipple
                                        sx={{marginLeft: '15px'}}>
                                        <img src={require('./../images/icon-alv-mesconsos-blanc.svg').default}
                                             alt="CristalHub"
                                             className="CristalIconButton"
                                        />
                                    </CristalWhiteButton>
                                </Grid>
                                <Grid item>
                                    <Grid container
                                          direction="column"
                                          justifyContent="center"
                                          alignItems="flex-start"
                                          sx={{marginLeft: '11px', marginTop: '5px'}}>
                                        <Grid item>
                                            <Typography>
                                                Vous êtes sur...
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                <span> {constantes.vousetessur} </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*  Contenu spécifique de chaque composant */}
                    {React.Children.map(children, child =>
                        React.cloneElement(child)
                    )}
                </Box>
            </Container>
        </>
    );
}
export default Layout;
